<template>
    <div class="deliveries-list-products-page">
        <div class="container-fluid">
            <h1>Lista produktów na dzień {{ day }}</h1>
            <b-button-group class="mr-2 mb-2 d-print-none">
                <b-button variant="secondary" :to="{name: 'adminDeliveriesList',  params: { day: day } }" >Adresy</b-button>
            </b-button-group>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />                
            <b-table :items="products" :fields="fields" :show-empty="true"
                class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych">
            </b-table>
        </div>
    </div>
</template>

<script>import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminDeliveriesListProducts',
    data() {
        return {
            isLoading: false,
            day: this.$route.params.day,
            products: null,
            fields: [
                { label: 'Produkt', key: 'product.name', sortable: true },
                { label: 'Ilość', key: 'total_amount', sortable: true }
            ]
        }
    },
    computed: {
    },
    mounted(){

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
        axios.get('/admin/delivery_list_products/' + this.day ).then( (responce) => {
            this.isLoading =  false;
            this.products = responce.data.products;
        }).catch( () => {
            this.isLoading = false;
            this.$bvToast.toast(`Błąd pobierania danych`, {
                title: 'Błąd',
                autoHideDelay: 5000,
                solid: true,
                variant: 'danger',
                appendToast: true,
                toaster: 'b-toaster-top-right'
            })
        });
    },
    methods: {
    },
}
</script>
